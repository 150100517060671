<template>
  <div>
    <label class="font-weight-bolder font-medium-4">Catalog Defaults</label>
    <b-row>
      <b-col>
        <div class="d-flex justify-content-end">
          <feather-icon
            v-if="organizationRequiresEditConfirmation"
            v-b-tooltip.noninteractive.hover.bottom
            :title="$t('Edit')"
            :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
            size="36"
            class="cursor-pointer"
            @click="isEditAble()"
          />
        </div>
      </b-col>
    </b-row>
    <error-alert
      :fields="catalogSettingsFields"
      :error="error"
    />
    <b-form>
      <validation-observer ref="customerSettingsCreateVal">
        <b-row>
          <b-col md="6">
            <component
              :is="catalogSettingsFields[field].type"
              v-for="field in [
                'catalog_defaults_is_active',
                'catalog_defaults_default_type_id',
              ]"
              :key="field"
              :ref="`${field}_ref`"
              v-model="catalogDefaults[field]"
              v-bind="getProps(field)"
              :is-editable="isEdit"
            />
            <div>
              <label class="inputLabel">{{
                $t("Default Fulfillment Rules")
              }}</label>
              <component
                :is="catalogSettingsFields[field].type"
                v-for="field in [
                  'catalog_defaults_default_fulfillment_rules_can_be_transferred',
                  'catalog_defaults_default_fulfillment_rules_requires_a_container',
                ]"
                :key="field"
                :ref="`${field}_ref`"
                v-model="catalogDefaults[field]"
                v-bind="getProps(field)"
                :is-editable="isEdit"
              />
            </div>
          </b-col>
          <b-col md="6">
            <div>
              <component
                :is="
                  catalogSettingsFields[
                    'catalog_defaults_default_allow_discount'
                  ].type
                "
                :key="`catalog_defaults_default_allow_discount`"
                :ref="`catalog_defaults_default_allow_discount_ref`"
                v-model="
                  catalogDefaults.catalog_defaults_default_allow_discount
                "
                v-bind="getProps('catalog_defaults_default_allow_discount')"
                :is-editable="isEdit"
              />
            </div>
            <div>
              <label class="inputLabel">{{
                $t("Default Assets’ Rules")
              }}</label>
              <component
                :is="catalogSettingsFields[field].type"
                v-for="field in ['catalog_defaults_default_assets_rules_needs_individual_asset_id',]"
                :key="field"
                :ref="`${field}_ref`"
                v-model="catalogDefaults[field]"
                v-bind="getProps(field)"
                :is-editable="isEdit"
                @change="handleIndividualAsset"
              />
              <component
                :is="catalogSettingsFields[field].type"
                v-for="field in ['catalog_defaults_default_assets_rules_requires_individual_asset_price']"
                :key="field"
                :ref="`${field}_ref`"
                v-model="catalogDefaults[field]"
                v-bind="getProps(field)"
                :is-editable="!isEdit?!isNeedsIndividual:isEdit"
              />
              <component
                :is="catalogSettingsFields[field].type"
                v-for="field in [
                  'catalog_defaults_default_assets_rules_can_be_procured',
                  'catalog_defaults_default_assets_rules_can_be_rented',
                  'catalog_defaults_default_assets_rules_can_be_sub_rented',
                  'catalog_defaults_default_assets_rules_can_be_sold',
                  'catalog_defaults_default_turnaround_time',
                ]"
                :key="field"
                :ref="`${field}_ref`"
                v-model="catalogDefaults[field]"
                v-bind="getProps(field)"
                :is-editable="isEdit"
              />
            </div>
          </b-col>
        </b-row>
        <label
          style="font-size: 20px"
          class="inputLabel"
        >{{
          $t("Default Preparation times")
        }}</label>
        <b-row>
          <b-col
            v-for="field in [
              'catalog_defaults_default_pick_time_per_item',
              'catalog_defaults_default_receive_time_per_item',
              'catalog_defaults_default_pack_time_per_item',
              'catalog_defaults_default_put_back_time_per_item']"
            :key="field"
            md="6"
          >
            <component
              :is="catalogSettingsFields[field].type"
              :ref="`${field}_ref`"
              v-model="catalogDefaults[field]"
              v-bind="getProps(field)"
              :is-editable="isEdit"
            />
          </b-col>
        </b-row>
        <portal to="body-footer">
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <b-button
                v-if="!isFormChanged"
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="$router.push({ name: 'settings' })"
              >
                {{ $t("Back to Setting") }}
              </b-button>
              <b-button
                v-else
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="loader"
              >
                <feather-icon
                  icon="LCancelIcon"
                  size="16"
                />
                {{ $t("Cancel") }}
              </b-button>
            </div>
            <div>
              <b-button
                variant="success"
                class="saveBtn font-medium-1 font-weight-bolder"
                :disabled="!isFormChanged"
                @click="submit"
              >
                {{ $t("SAVE") }}
              </b-button>
            </div>
          </div>
        </portal>
      </validation-observer>
    </b-form>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
</template>
<script>
import { required } from '@/libs/validations/validations'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import { ValidationObserver } from 'vee-validate'
import {
  BButton,
  BCol,
  BForm,
  BRow,
  BFormCheckbox,
  VBTooltip,
} from 'bootstrap-vue'
import { scrollToError } from '@core/utils/utils'
import store from '@/store'
import config from '../defaultsConfig'

export default {
  name: 'DefaultForm',
  components: {
    ErrorAlert,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      error: {},
      required,
      onSubmit: false,
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    catalogDefaults() {
      return this.$store.state[this.MODULE_NAME].catalogDefaultForm
    },
    isNeedsIndividual() {
      return this.catalogDefaults.catalog_defaults_default_assets_rules_needs_individual_asset_id
    },
    settingsCatalogDefaultsFormClone() {
      return this.$store.state[this.MODULE_NAME_CLONE]
        .settingsCatalogDefaultsForm
    },
    isFormChanged() {
      return (
        JSON.stringify(this.catalogDefaults)
        !== this.settingsCatalogDefaultsFormClone
      )
    },
  },
  methods: {
    handleIndividualAsset(value) {
      if (!value) {
        this.catalogDefaults.catalog_defaults_default_assets_rules_requires_individual_asset_price = value
      }
    },
    isEditAble() {
      this.isEdit = !this.isEdit
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) {
        this.$refs['confirm-modal'].confirmWarning(
          next,
          this.isFormChanged,
          this.onSubmit,
        )
      }
    },
    submit() {
      this.error = {}
      this.onSubmit = true

      this.$refs.customerSettingsCreateVal.validate().then(success => {
        if (success) {
          const data = this.catalogDefaults
          const mappedData = {}
          // eslint-disable-next-line no-restricted-syntax
          for (const item in data) {
            if (typeof data[item] !== 'object') {
              mappedData[item] = `${data[item]}`
            }
          }
          const defaultSettings = this.mappingFields(
            Object.keys(this.catalogSettingsFields),
            data,
            {
              ...mappedData,
              catalog_defaults_default_assets_rules_needs_individual_asset_id: mappedData.catalog_defaults_default_assets_rules_needs_individual_asset_id === 'true' ? 1 : 0,
              catalog_defaults_default_assets_rules_requires_individual_asset_price: mappedData.catalog_defaults_default_assets_rules_requires_individual_asset_price === 'true' ? 1 : 0,

              catalog_defaults_is_active: mappedData.catalog_defaults_is_active === 'true' ? 1 : 0,
              catalog_defaults_default_assets_rules_can_be_procured: mappedData.catalog_defaults_default_assets_rules_can_be_procured === 'true' ? 1 : 0,
              catalog_defaults_default_assets_rules_can_be_rented: mappedData.catalog_defaults_default_assets_rules_can_be_rented === 'true' ? 1 : 0,
              catalog_defaults_default_assets_rules_can_be_sub_rented: mappedData.catalog_defaults_default_assets_rules_can_be_sub_rented === 'true' ? 1 : 0,
              catalog_defaults_default_assets_rules_can_be_sold: mappedData.catalog_defaults_default_assets_rules_can_be_sold === 'true' ? 1 : 0,
              catalog_defaults_default_fulfillment_rules_requires_a_container: mappedData.catalog_defaults_default_fulfillment_rules_requires_a_container === 'true' ? 1 : 0,
              catalog_defaults_default_fulfillment_rules_can_be_transferred: mappedData.catalog_defaults_default_fulfillment_rules_can_be_transferred === 'true' ? 1 : 0,

              catalog_defaults_default_type_id:
                data.catalog_defaults_default_type_id.id,
            },
          )
          this.sendNotification(
            this,
            defaultSettings,
            `${this.MODULE_NAME}/updateDefaultSettings`,
          )
            .then(() => {
              this.$emit('refetch')
              this.clear()
            })
            .catch(err => {
              this.error = err.response?.data
            })
            .finally(() => {
              this.onSubmit = false
            })
        } else {
          scrollToError(this, this.$refs.customerSettingsCreateVal)
        }
      })
    },
    clear() {
      this.$refs.customerSettingsCreateVal.reset()
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, {})
    },
    loader() {
      this.$refs.customerSettingsCreateVal.reset()
      const smth = JSON.parse(this.settingsCatalogDefaultsFormClone)
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, smth)
    },
    getProps(fieldName) {
      return {
        field: this.catalogSettingsFields[fieldName],
        name: fieldName,
      }
    },
  },
  setup() {
    const MODULE_NAME = 'settings-catalog-defaults'
    const MODULE_NAME_CLONE = 'cloneData'
    const { catalogSettingsFields } = config()
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      catalogSettingsFields,
    }
  },
}
</script>
